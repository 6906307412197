import ConnectComponent from "../../Services/ConnectionService";
import Loading from "../Loading";
import homeStyle from "../Home.module.css";
import React from "react";
import footerStyle from "../Footer/Footer.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faChildReaching, faClock, faStar, faUserGraduate} from "@fortawesome/free-solid-svg-icons";
import {encode} from "../../Services/ObfuscatorService";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import style from "./StartActivity.module.css";
import HeaderWelcome from "../Header/HeaderWelcome/HeaderWelcome";
import ligneBrisee from "../../assets/LigneBrisee/ligne_brisee_1.1.svg"
import etoile from "../../assets/Etoile.svg"

export default class StartActivity extends ConnectComponent {

    constructor(props) {
        super(props);
        this._activity = undefined;
        this._user = undefined;
        this.isLoadingA = true;
        this.isLoadingU = true;
        this._carouselItems = [];
        this.state = {
            height: 0,
            width: 0
        }
        this.canRequest = true
    }


    doesMustLog() {
        this.mustLog = 1
    }

    getSize() {
        const height = this.divElement.clientHeight;
        const width = this.divElement.clientWidth;
        this.setState({height: height, width: width});
    }

    _loadActivity() {
        if(this._activity === undefined){
            let request = new Request (process.env.REACT_APP_API_URL + "/activity/" + this.props.act + '/' + this.props.user, {method:'POST', credentials:'include'});
            this.fetchJsonOrError(request,
                (data) => {
                    this._activity = data;
                    this._carouselItems = [];
                    for(const media in this._activity.media){
                        this._carouselItems.push({src: process.env.REACT_APP_CDN_URL + "/uploads/activity/" + this._activity.media[media]})
                    }
                    this.isLoadingA = false
                    this.forceUpdate()
                    document.title = this._activity.name + " - App Web Atorika";
                }
            )
        }
    }

    _loadUser() {
        if(this._user === undefined){
            let request = new Request (process.env.REACT_APP_API_URL + "/user/" + this.props.user, {method:'POST', credentials:'include'});
            this.fetchJsonOrError(request,
                data => {
                        this._user = data.user;
                        this.isLoadingU = false
                        this.forceUpdate()
                    }
                )
        }
    }

    startActivity = () => {
        if(this.canRequest) {
            this.canRequest = false
            let request = new Request(process.env.REACT_APP_API_URL + '/history/start/' + this.props.act
                + '/user/' + this.props.user, {method:'POST', credentials:'include'})
            this.fetchJsonOrError(request, null, null, (resp) => {
                if(resp.status === 200) {
                    this.goError()
                }
            })
            document.body.requestFullscreen()
            this.setState({shouldNavigate: true, navigateTo: "/activity/" + encode(this.props.act) + "/" + encode(this.props.user)})
            this.setTimeout(() => {this.canRequest = true}, 5000)
        }
    }

    renderCarousel() {
        let images = [];
        for (let carouselItemsKey in this._carouselItems) {
            let arr = this._carouselItems[carouselItemsKey].src.split(".");
            let type = arr[arr.length - 1];

            if(type === "mp4" || type === "MP4"){
                images.push(
                    <div style={{height: "100%", width: this.state.width + "px"}}>
                        <video
                            src={this._carouselItems[carouselItemsKey].src}
                            style={{height: "100%", width: this.state.width + "px", objectFit: "cover"}}
                            autoPlay={true}
                            muted={true}
                            loop={true}
                        />
                    </div>
                )
            } else {
                images.push(
                    <div style={{height: "100%", width: "100%"}}>
                        <img src={this._carouselItems[carouselItemsKey].src} alt={"Image"} style={{height: "100%", width: "100%", objectFit: "cover"}}/>
                    </div>
                )
            }
        }
        return images
    }

    renderChild() {
        if(this.isLoadingA || this.isLoadingU) {
            this._loadActivity()
            this._loadUser()
            return (
                <div
                    style={{
                        position: "absolute",
                        left: 0, top: 0,
                        opacity: '100%',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white"
                    }}
                >
                    <Loading/>
                </div>
            )
        }
        return (
            <div className={homeStyle.mainContainer}>

                <div className={homeStyle.headerContainer}>
                    <HeaderWelcome
                        name={this._activity.name}
                        theme={this._activity.them}
                        themeColor={this._activity.themColor}
                        themeBaseColor={this._activity.themBaseColor}
                        secondTheme={this._activity.secondThem}
                        secondThemeColor={this._activity.secondThemColor}
                        secondThemeBaseColor={this._activity.secondThemBaseColor}
                        duration={this._activity.estimatedDuration}
                        ageMin={this._activity.ageMin}
                        progressBar={false}
                        dialogue={this._activity.mascotte.welcome.dialogue.replace("_name_", this._user.firstName)}
                        file={this._activity.mascotte.welcome.file}
                        orientation={this._activity.mascotte.welcome.orientation}
                        type={this._activity.mascotte.welcome.type}
                    />
                </div>

                <div className={homeStyle.screenContainer}>
                    <div className={style.container} style={{flexDirection: "column", position: "relative"}}>
                        <div
                            style={{height: "70%", width: "100%", position: "relative", display: "flex"}}
                            ref={(divElement) => { this.divElement = divElement }}
                            onLoad={() => this.getSize()}
                        >
                            <Carousel
                                autoPlay={true}
                                infiniteLoop={true}
                                dynamicHeight={false}
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={false}
                                width={"100%"}
                            >
                                {this.renderCarousel()}
                            </Carousel>

                            <img src={ligneBrisee} style={{position: "absolute", bottom: "-1px", width: "100%", zIndex: "5"}}/>
                        </div>

                        <div style={{height: "30%", display: "flex", flexDirection: "column", alignItems: "center", gap: "60px"}}>
                            <div className={style.title}>{this._activity.name}</div>

                            <div style={{display: "flex", flexDirection: "row", fontSize: "1.3vmax", width: "70%", maxWidth: "500px"}}>
                                <div className={style.infosContainer}>
                                    <img src={etoile} style={{height: "1.6vmax"}}/>
                                    {this._activity.realXp}
                                </div>
                                <div className={style.infosContainer}>
                                    <FontAwesomeIcon icon={faClock}/>
                                    {this._activity.estimatedDuration} min
                                </div>
                                <div className={style.infosContainer}>
                                    <FontAwesomeIcon icon={faUserGraduate}/>
                                    {this._activity.ageMin} +
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={homeStyle.footerContainer}>
                    <div className={footerStyle.footer}>
                        <div className={footerStyle.starContainer}>
                        </div>
                        <div className={footerStyle.nextContainer}>
                            <button onClick={this.startActivity} className={style.chevron}>
                                <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "40px"}} color={"#6A6A6B"}/>
                            </button>
                        </div>
                        <div className={footerStyle.timerContainer} style={{flex: 6}}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
