import React, {Component} from "react"
import {faPause, faPlay, faUndo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class VideoComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            height: 0,
            width: 0,
            paused: true,
            focus: false,
            finish: false
        }
    }

    PauseAndPlay() {
        if(this.state.finish){
            this.vid.currentTime = 0;
            this.setState({finish: false})
        }

        if(this.state.paused){
            this.vid.play();
            this.setState({paused: false})
        } else {
            this.vid.pause();
            this.setState({paused: true})
        }
    }

    Pause() {
        if(this.vid) this.vid.pause();
    }

    Play() {
        if(this.vid) {
            if(this.state.paused) {
                this.setState({paused: false})
            }
            this.vid.play()
        }
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        const width = this.divElement.clientWidth;
        this.setState({ height: height, width: width, heightMax: (height/width < 338/236) });

        if(this.props.autoPlay){
            if(!this.state.focus && this.props.focused){
                this.setState({focus: true})
                this.PauseAndPlay()
            } else {
                this.setState({focus: this.props.focused});
            }
        }
    }

    calcPlay() {
        if(!this.props.focused && this.state.focus) {
            this.Pause()
            this.setState({paused: true, focus: false})
        }

        if(this.props.autoPlay && this.props.focused && !this.state.focus && !this.state.finish){
            this.Play()
            this.setState({focus: true})
        }
    }

    render() {

        setTimeout(() => {this.calcPlay()}, 100)

        return (
            <div
                style={{flex: this.props.size, maxHeight: this.props.size * 100 / 6 + "%", display: "flex", position: "relative", alignItems: "center", justifyContent: "center"}}
                ref={(divElement) => { this.divElement = divElement }}>

                <video
                    src={process.env.REACT_APP_CDN_URL + '/uploads/steps/videos/' + this.props.file}
                    style={{height: "100%", width: this.props.broken || this.props.forced ? "100%" : undefined, objectFit: "cover"}}
                    onClick={() => this.PauseAndPlay()}
                    loop={this.props.loop}
                    autoPlay={false}
                    controls={false}
                    muted={false}
                    ref={(vid) => { this.vid = vid }}
                    onEnded={() => {
                        this.setState({finish: true, paused: true});
                    }}
                />

                {this.state.paused && !this.state.finish &&
                    <div
                        style={{
                            position: "absolute",
                            top: 0, left: 0,
                            width: "100%", height: "100%",
                            backgroundColor: 'rgba(0, 0, 0, 0.33)',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onClick={() => this.PauseAndPlay()}
                    >
                        <FontAwesomeIcon icon={faPlay} style={{fontSize: "3vmax", color: "white"}}/>
                    </div>
                }

                {this.state.finish &&
                    <div
                        style={{
                            position: "absolute",
                            top: 0, left: 0,
                            width: "100%", height: "100%",
                            backgroundColor: 'rgba(0, 0, 0, 0.33)',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onClick={() => {
                            this.PauseAndPlay()
                        }}
                    >
                        <FontAwesomeIcon icon={faUndo} style={{fontSize: "3vmax", color: "white"}}/>
                    </div>
                }

                {this.props.broken &&
                    <img
                        src={require("../../../assets/LigneBrisee/" + this.props.ligneBrisee)}
                        style={{position: "absolute", width: "101%", bottom: "-1px"}}
                    />
                }

            </div>
        )
    }
}

export default VideoComponent;
