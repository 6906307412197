import React, {Component} from "react"
import Lottie from "lottie-react";
import atorika from "../assets/Lottie/logo-anime-atorika_no-intro.json"
import logo from "../assets/Logo_bocal.svg"


class Loading extends Component {

    render () {

        return (
            <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", height: "100%"}}>
                <Lottie animationData={atorika} autoplay={true} style={{height: "95%"}}/>
            </div>
        );
    }
}

export default Loading;
