import React, {Component} from "react"
import "./TitleComponent.module.css"

class ImageComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            height: 0,
            width: 0,
        }
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        const width = this.divElement.clientWidth;
        this.setState({ height: height, width: width, heightMax: (height/width < 338/236) });
    }

    render() {
        return (
            <div style={{flex: this.props.size, maxHeight: this.props.size * 100 / 6 + "%",display: "flex", alignItems: "center", justifyContent: "center", position: "relative"}} ref={ (divElement) => { this.divElement = divElement } }>
                <img
                    src={process.env.REACT_APP_CDN_URL + '/uploads/steps/images/' + this.props.file}
                    style={{
                        objectFit: "cover",
                        height: "100%",
                        width: this.props.broken || this.props.forced ? "100%" : undefined
                    }}
                />
                {this.props.broken &&
                    <img
                        src={require("../../../assets/LigneBrisee/" + this.props.ligneBrisee)}
                        style={{position: "absolute", bottom: '-1px', width: "101%"}}
                    />
                }
            </div>
        )
    }
}

export default ImageComponent;