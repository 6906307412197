import React, {Component} from "react"
import style from "./MaterialComponent.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import MascotteComponent from "./MascotteComponent";

class MaterialComponent extends Component {

    constructor(props) {
        super(props)
        this.allChecked = false;
        this.state = {
            materials: this.props.materials
        };
    }

    calculateShowNext() {
        this.props.setShowNext(this.allChecked)
    }

    calculateAllChecked() {
        let allChecked = true
        for(let i in this.state.materials) {
            allChecked = allChecked && this.state.materials[i]['check']
        }
        this.allChecked = allChecked
        this.calculateShowNext()
    }

    async nextTrigger() {
        return true
    }

    showIcon(material){
        return(
            <button
                className={style.iconBtn}
                onClick={()=>{
                    this.state.materials[material]['check'] = !this.state.materials[material]['check'];
                    this.calculateAllChecked()
                    this.forceUpdate();
                }}
            >


                <div
                    style={{
                        height: "70%",
                        width: "100%",
                        alignItems: 'center',
                        justifyContent: 'center'
                }}>

                    <img
                        src={process.env.REACT_APP_CDN_URL + '/uploads/steps/icons/' + this.state.materials[material]["icon"]}
                        style={{ height: '90%', width: undefined, objectFit: "contain"}}
                    />

                </div>


                { this.state.materials[material]['check'] &&
                    <div style={{
                        borderColor: 'lightgrey',
                        borderWidth: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.75)',
                        position: 'absolute', right: '25%', top: '10%',
                        padding: 4,
                        borderRadius: 100,
                        aspectRatio: 1,
                        width: "35px",
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: "flex"
                    }}>
                        <FontAwesomeIcon icon={faCheck} color={'green'} style={{fontSize: "1.5vmax"}}/>
                    </div>
                }

                {this.state.materials[material].quantity != 1 &&
                    <div
                        style={{
                            position: 'absolute',
                            left: '25%',
                            top: '10%',
                            borderWidth: 1,
                            padding: 4,
                            aspectRatio: 1,
                            width: "35px",
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: "flex",
                            borderRadius: 100,
                            borderColor: 'lightgrey',
                            backgroundColor: 'rgba(255, 255, 255, 0.75)'
                        }}>
                        <span style={{ bottom: 2, fontSize: "1.2vmax"}}>x{this.state.materials[material].quantity}</span>
                    </div>
                }


                <div style={{
                    fontSize: "1.2vmax",
                    textAlign: 'center'
                }}>
                    {this.state.materials[material]["name"]}
                </div>

            </button>
        )
    }

    showIcons(){
        let count = Object.keys(this.state.materials).length;
        if(count >= 3){
            let compteur = 1
            let view1 = [];
            let view2 = [];
            for(const material in this.state.materials){
                if(compteur <= Math.ceil(Object.keys(this.state.materials).length/2)){
                    view1.push(this.showIcon(material));
                } else {
                    view2.push(this.showIcon(material));
                }
                compteur += 1
            }
            return(
                <div
                    style={{
                        flex: 1,
                        alignItems: 'center',
                        backgroundColor: "#e8f0f2",
                        marginHorizontal: 10,
                        borderRadius: 10,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%"
                }}>
                    <div style={{flex: 1, flexDirection: "row", display: "flex", maxHeight: "50%", width: "100%", justifyContent: "center"}}>
                        {view1}
                    </div>
                    <div style={{flex: 1, flexDirection: "row", display: "flex", maxHeight: "50%", width: "100%", justifyContent: "center"}}>
                        {view2}
                    </div>
                </div>
            )
        }
        else  {
            let view1 = [];
            for(const material in this.state.materials){
                view1.push(this.showIcon(material));
            }
            return(
                <div style={{
                    flex: 1,
                    alignItems: 'center',
                    backgroundColor: "#e8f0f2",
                    marginHorizontal: 10,
                    borderRadius: 10,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                }}>
                    <div style={{flex: 1}}/>
                    <div style={{flex: 2, flexDirection: "row", display: "flex", width: "100%", justifyContent: "center", maxHeight: "50%"}}>
                        {view1}
                    </div>
                    <div style={{flex: 1}}/>
                </div>
            )
        }


    }

    render() {
        this.calculateAllChecked()
        return (
            <div style={{flex: 1, display: "flex", flexDirection: "column", maxHeight: "100%"}}>

                {/* Mascotte */}
                <div style={{flex:4, maxHeight: "58%", display: "flex"}}>
                    <MascotteComponent
                        orientation={"droite"}
                        file={this.props.file}
                        type={this.props.type}
                        dialogue={this.props.dialogue}
                        margin={"40px"}
                        materials={true}
                    />
                </div>

                <div style={{flex:3, display: "flex", maxHeight: "42%", margin: "5px 10px"}}>

                    {this.state.materials && this.showIcons()}

                </div>
            </div>
        )
    }
}

export default MaterialComponent;
