import React from "react"
import {Component} from "react";
import styles from "./BoxCard.module.css"
import ThemeBadge from "./ThemeBadge";

class BoxCard extends Component {
    render() {
        return (
            <div className={styles.childCard}>
                <div
                    className={styles.card}
                    style={{
                        padding: "30px",
                        flex: 1,
                        backgroundColor: "#f3f6f8",
                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                        borderRadius: "5px",
                        display: "flex",
                        height: "100%",
                        boxSizing: "border-box",
                        flexDirection: "column"
                    }}
                >
                    <div style={{flex: 5}}>
                        <h2 style={{margin: "0 0 15px 0"}}>
                            {this.props.box.name}
                        </h2>

                        <div style={{marginBottom: "20px"}}>

                            <ThemeBadge
                                theme={this.props.box.theme}
                                bgColor={this.props.box.themeColor}
                                baseColor={this.props.box.themeBaseColor}
                                style={{fontSize: "12px", fontWeight: 500}}
                            />

                            { this.props.box.secondaryTheme &&
                                <ThemeBadge
                                    theme={this.props.box.secondaryTheme}
                                    bgColor={this.props.box.secondaryThemeColor}
                                    baseColor={this.props.box.secondaryThemeBaseColor}
                                    style={{marginLeft: "5px", fontSize: "12px", fontWeight: 500}}
                                />
                            }
                        </div>

                        <div style={{flexDirection: window.innerWidth > 1020 ? "row" : "column", display: "flex"}}>
                            <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <img
                                    src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + this.props.box.media[0]}
                                    style={{maxWidth: "100%", maxHeight: "100%", borderRadius: "8px", marginRight: "10px"}}
                                />
                            </div>
                            <div style={{flex: 2, display: "flex", marginLeft: window.innerWidth > 960 ? "5px" : 0, flexDirection: "column"}}>

                                <div style={{margin: "10px 0 5px"}}>
                                    {this.props.box.description}
                                </div>

                                <div style={{margin: "5px 0 10px"}}>
                                    Age : {this.props.box.age} +
                                </div>

                                <div style={{margin: "5px 0"}}>
                                    {this.props.box.stock >= 10 &&
                                        <span className={styles.badge} style={{backgroundColor:"#6fa939"}}>
                                            En stock
                                        </span>
                                    }

                                    {this.props.box.stock <= 0 &&
                                        <span className={styles.badge} style={{backgroundColor:"#52BAEC"}}>
                                            En précommande
                                        </span>
                                    }

                                    {this.props.box.stock < 10 && this.props.box.stock > 0 &&
                                        <span className={styles.badge} style={{backgroundColor:"#f39200"}}>
                                            Moins de 10 en stock
                                        </span>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "center"
                        }}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end", textAlign: "left", marginTop: "25px"}}>
                            <a className={styles.defaultBtn} href={this.props.link} style={{fontSize: "1rem"}}>
                                Ajouter au panier
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BoxCard;
