import styles from './NotFound.module.css';
import React from "react"
import LoggedComponent from "../../Services/ConnectionService";
import homeStyle from "../Home.module.css";
import AtoRikaTristes from '../../assets/ato_rika_tristes.svg';

class NotFound extends LoggedComponent {


    doesMustLog = () => {
        this.mustLog = 1
    }

    renderChild () {
        return (
            <div className={styles.mainContainer}>
                <div className={styles.subContainer}>
                    <div className={styles.robot}>
                        <img src={AtoRikaTristes} style={{maxWidth: "350px"}}/>
                    </div>
                    <div className={styles.infos}>
                        <h1 className={styles.title}>Oops !</h1>

                        <h2 className={styles.subTitle}>L'expérience a mal tourné avec cette page...</h2>

                        <div className={styles.text}>
                            Il s’agit d’une erreur 404. <br/>
                            Vérifie si le lien que tu essaies d’ouvrir est correct. <br/>
                            Tu peux aussi aller sur la page d’accueil. <br/>
                        </div>

                        <a className={styles.defaultBtn} href={process.env.REACT_APP_SHOP_URL}>
                            Aller sur la page d'accueil
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
